<template>
  <div class="home_background">

    <div v-if="mobile_contents">
      <MyJumbotronMobile />
      <PlofileInfomationMobile />
      <ConceptMobile />
      <AboutBusinessMobile />
    </div>

    <div v-else>
      <MyJumbotron />
      <PlofileInfomation />
      <Concept />
      <AboutBusiness />
    </div>
    <Examples :primaryColor="colors.lightBlue.accent4" />
    <Contact :primaryColor="colors.lightBlue.accent4" />
  </div>
</template>

<script>
import colors from 'vuetify/lib/util/colors'

import MyJumbotron from '../components/MyJumbotron.vue';
import PlofileInfomation from '../components/PlofileInfomation.vue';
import Concept from '../components/Concept.vue';
import AboutBusiness from '../components/AboutBusiness.vue';
import Contact from '../components/Contact.vue';
import Examples from '../components/Examples.vue'

import MyJumbotronMobile from '../components/mobileComponents/MyJumbotronMobile.vue';
import PlofileInfomationMobile from '../components/mobileComponents/PlofileInfomationMobile.vue';
import ConceptMobile from '../components/mobileComponents/ConceptMobile.vue';
import AboutBusinessMobile from '../components/mobileComponents/AboutBusinessMobile.vue';

export default {
  name: 'home',
  components: {
    MyJumbotron,
    PlofileInfomation,
    Concept,
    AboutBusiness,
    Contact,
    Examples,

    MyJumbotronMobile,
    PlofileInfomationMobile,
    ConceptMobile,
    AboutBusinessMobile,
  },

  data: () => ({
    visible: false,
    width: window.innerWidth,
    height: window.innerHeight,
    mobile_contents: true,
    colors,
  }),

  // DOMが作成される前に実行
  created() {
      window.addEventListener('resize', this.handleResize)
      this.handleResize()
  },

  methods: {
    handleResize: function() {
      // resizeのたびにこいつが発火
      this.width = window.innerWidth;
      this.height = window.innerHeight;

      if (window.innerWidth <= 600) {
        this.mobile_contents = true;
      } else {
        this.mobile_contents = false;
      }
    }
  },
  mounted: function () {
    //マウスによるクリックやキーボードからの入力といった様々なイベント処理を実行するメソッド
    //対象の要素.addEventListener(種類, 関数, false);
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  }
};
</script>

<style scoped>
body {
  background-color: #ffffff;
}
</style>