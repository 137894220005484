<template>
  <div class="my_jumbotronMobile">
    <div class="office card">
      <img class="office__image" src="../assets/hikarusite_office.jpg">
        <div class="office_title message">
          <p class="color">Office</p>
          <p>cleaning</p>
        </div>
    </div>
    <div class="house card">
      <img class="house__image" src="../assets/hikarusite_house.jpg">
      <div class="house_title message">
        <p class="color">House</p>
        <p>cleaning</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyJumbotron',
  data: () => ({
 
  }),
}
</script>

<style scoped>
.my_jumbotronMobile {
  position: relative;
  overflow: hidden;
  background-color: rgb(199, 243, 255);
  height: 100vh;
  display: flex;
}
p {
  font-size: 6vw;
}
img {
  object-fit: cover;
  z-index: 1;
  transform: scale(2.5);
}
.card {
  position: relative;
	height: 50vh;
}
.message {
  z-index: 1000;
  position: absolute;
  width: 40vw;
  height: 20vw;
  opacity: 90%;
  text-align: center;
  line-height: 7vw;
  padding-top: 3vw;
  letter-spacing: 1vw;
}
.color {
  color: rgb(199, 243, 255);
}
.office {
  clip-path: polygon(0 0, 100% 0, 0% 100vh);
}
.office__image {
  width: 100%;
}
.office_title {
  top: 0px;
  background-color: #fff;
}
.house {
  clip-path: polygon(50% 0%, 100% -50vh, 100% 100%, 0% 100%);
  position: absolute;
  bottom: 0vh;
}
.house__image {
  width: 80%;
}
.house_title {
  bottom: 0vw;
  right: 0vw;
  background-color: #fff;
}
</style>