<template>
  <div class="my_jumbotronMobile">
    <div class="office card">
      <img class="image" src="../../assets/hikarusite_office.jpg">
        <div class="office_title message">
          <p class="color">Office</p>
          <p>cleaning</p>
        </div>
    </div>
    <div class="house card">
      <img class="image" src="../../assets/hikarusite_house.jpg">
      <div class="house_title message">
        <p class="color">House</p>
        <p>cleaning</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyJumbotronMobile',
  data: () => ({
  }),
}
</script>

<style scoped>
.my_jumbotronMobile {
  overflow: hidden;
  background-color: rgb(199, 243, 255);
  height: 100vh;
}
p {
  font-size: 4vw;
  font-weight: bold;
}
.image {
  width: 134vh;
  position: absolute;
  left: -45%;
  height: 50vh;
}
img {
  object-fit: cover;
  z-index: 1;
}
.card {
  position: relative;
}
.message {
  z-index: 1000;
  position: absolute;
  width: 100%;
  height: 10vh;
  opacity: 90%;
  text-align: center;
  line-height: 4vh;
  padding-top: 1vh;
  letter-spacing: 1vw;
}
.color {
  color: rgb(199, 243, 255);
}
.office {
 height: 50vh;
}
.office_title {
  top: 0px;
  background-color: #fff;
}
.house {
  height: 50vh;
}
.house_title {
  background-color: #fff;
}
</style>