<template>
    <section class="concept-block">
        <img :src=img1 v-rellax="{speed: 0.5}" class="concept-block__driving_image">
        <img :src=img2 v-rellax="{speed: 1}" class="concept-block__baseball_image">
        <img :src=img3 v-rellax="{speed: 3}" class="concept-block__game_image">
        <div class="concept-block__subtitle">
            <p class="concept-block__subtitle__textinfo">
                Professional cleaning is more than just cleaning.
            </p>
        </div>
        <div class="concept-block__description">
            <p class="concept-block__description__textinfo">
                It has the advantage of increasing your free time while maintaining a comfortable space.
            </p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'ConceptMobile',
    data: () => ({
        img1: require("@/assets/img/driving.jpg"),
        img2: require("@/assets/img/watching_baseball.png"),
        img3: require("@/assets/img/watching_game.jpg"),
    }),
}
</script>


<style lang="scss" scoped>

//ここが今、neco8さんが言ってたCSSの記法
.concept-block {
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  background-color: white;

  &__subtitle {
    top: 3vh;
    margin: 5vw;
    text-align: center;

    &__textinfo {
        font-size: 6vw;
    }
  }

  &__description {
    position: absolute;
    left: 40vw;
    top: 45vh;
    width: 55vw;

    &__textinfo {
        font-size: 4vw;
    }
  }

  &__driving_image {
    position: absolute;
    top: 30vh;
    right: 5vw;
    height: 30vw;
    overflow: hidden;
    border-radius: 80px;
    box-shadow: 15px 15px 0px 0 rgb(206, 232, 255);
  }

  &__baseball_image {
    position: absolute;
    bottom: -30vh;
    right: 35vw;
    width: 30vh;
    overflow: hidden;
    border-radius: 400px;
    box-shadow: 15px 15px 0px 0 rgb(206, 232, 255);
  }

  &__game_image {
    position: absolute;
    top: 125vh;
    left: 1vw;
    width: 35vw;
    overflow: hidden;
    border-radius: 50px;
    box-shadow: 15px 15px 0px 0 rgb(206, 232, 255);
  }
}


</style>

