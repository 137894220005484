<template>
  <div class="contact">
    <div class="contact--title" :style="{ color: primaryColor }">Contact Us</div>
    <v-form class="contact--form" v-model="valid">
      <div class="contact--form--email">
        <v-text-field placeholder="example@email.com" label="Email" autocomplete v-model="email" :rules="emailRules"
          required />
      </div>
      <div class="contact--form--subject">
        <v-text-field placeholder="Subject" label="Subject" v-model="subject" :rules="subjectRules" required />
      </div>
      <div class="contact--form--content">
        <v-textarea v-model="content" label="Content" placeholder="Your Message" :rules="contentRules"
          required="false" />
      </div>
      <div class="contact--form--buttons">
        <v-btn class="contact--form--buttons--submit-button" @click="submit" :color="primaryColor" elevation="1">Submit
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
const isEmailValid = (str) => {
  const regex = /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
  return regex.test(str)
}

const submit = email => subject => content => async () => {
  alert(JSON.stringify({ email, subject, content }));
}

export default {
  name: 'Contact',
  props: ['primaryColor'],
  methods: {
    submit: function () {
      alert('message')
      if (!this.valid) return;
      submit(this.email)(this.subject)(this.content)();
    }
  },
  data: () => ({
    valid: false,
    email: '',
    emailRules: [
      (v) => Boolean(v) || 'Email is required.',
      (v) => isEmailValid(v) || 'Email must be valid.',
    ],
    subject: '',
    subjectRules: [
      v => Boolean(v) || 'Subject is required.',
    ],
    content: '',
    contentRules: [
      (v) => Boolean(v) || 'Content is required.',
    ],
  }),
}
</script>

<style scoped lang="scss">
.contact {
  position: relative;
  background-color: #fff;
  overflow: hidden;
  margin-bottom: 5rem;

  &--title {
    font-weight: bold;
    font-size: 2rem;
    padding: 1rem 1rem 0;
  }

  &--form {
    &--email {
      padding: 0 1rem 0 1rem;
    }

    &--subject {
      padding: 0 1rem 0 1rem;
    }

    &--content {
      padding: 0 1rem 0 1rem;
    }

    &--buttons {
      padding: 1rem 1rem 0;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: auto;
      gap: 0.5rem;
      place-items: start;

      &--submit-button {
        color: white !important;
      }
    }
  }
}
</style>