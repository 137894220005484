<template>
  <div class="career">
    <div class="career__title">
      <p class="career__title__font">Who am I?</p>
    </div>

    <div class="career__icon">
      <img :src=img1 class="career__icon__picture">
    </div>

    <div class="career__introduction">
      <p class="career__introduction__say-hello">hello!<p>
      <p class="career__introduction__plane-text">I'm Hikaru and I'm from Japan.
      Nice to meet you !!</p>
    </div>


    <div class="career__business">
      <h2 class="career__business__subtitle">What i can do.</h2>
      <ul>
        <li>House cleaning and business cleaning</li>
        <p>I have 8 years of experience house cleaning and business cleaning.</p>
        <li>Transportation industry</li>
        <p>I have an international driver's license and used to drive a lot in Japan.</p>
      </ul>
    </div>

    <div class="career__future">
      <h2 class="career__future__subtitle">comment</h2>
      <p>I worked as a business owner in Japan, and came to Australia to make friends overseas and learn about working styles and cultures.
      I'm still learning English, but feel free to contact me!</p>
    </div>
  </div>

</template>

<script>
export default {
  name: 'PlofileInfomationMobile',
  components: {

  },
  data: () => ({
    img1: require("@/assets/img/profile_picture.png"),
  }),
}
</script>


<style lang="scss" scoped>

.career {
  overflow: hidden;
  background-color: rgb(208, 246, 255);
  height: auto;

  &__title {
    margin: 2vh;
    text-align: center;
    width: 100vw;

    &__font {
      font-size: 10vw;
    }
  }

  &__icon {
    left: 0px;
    width: 100%;
    text-align: center;

    &__picture {
      width: auto;
      max-height: 30vh;
      border-radius: 200px;
      background-color: aliceblue;
    }
  }
  
  &__introduction {
    width: 100%;
    padding-left: 10vw;

    &__say-hello {
      font-size: 8vw;
    }

    &__plane-text {
      font-size: 6vw;
    }
  }

  &__business {
    padding: 2vw;
    width: 100vw;

    &__subtitle {
      text-align: center;
  }
  }

  &__future {
    padding: 2vw;
    width: 100vw;

    &__subtitle {
      text-align: center;
    }
  }
}
</style>

