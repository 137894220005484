<template>
    <section class="about-business-block">
        <div class="container-left">
            <div class="container-left__element">
                <p class="container-left__element__title">house cleaning</p>
                <img :src=img1 class="container-left__element__img">
                <p class="container-left__element__explanation">Whether it’s dusting, sweeping, vacuuming, mopping floors, changing bed sheets, scrubbing showers, doing laundry, or any of the other you want cleaning</p>
            </div>
        </div>
        <div class="container-right">
            <div class="container-right__element">
                <p class="container-right__element__title">business cleaning</p>
                <img :src=img2 class="container-right__element__img">
                <p class="container-right__element__explanation">Cleaning office desks, cleaning windows, cleaning carpets, dusting ventilation fans and air conditioners, etc.<br></p>
            </div>
        </div>
    </section>
</template>

<script>
import Vue from 'vue';
import VueRellax from 'vue-rellax';
Vue.use(VueRellax);

export default {
    name: 'AboutBusinessMobile',
    data: () => ({
        img1: require("@/assets/img/house-illustration.png"),
        img2: require("@/assets/img/company-illustration.png"),
    }),
}
</script>


<style lang="scss" scoped>
.about-business-block {
  display: block;
  background: linear-gradient( rgb(199, 243, 255),rgb(113, 221, 251));
  height: auto;
  width: 100%;
}

.container-left {
    width: 100%;
    padding-top: 3vh;

    &__element {
        text-align: center;
        box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.5);
        width: 90vw;
        background-color: white;
        margin: 5vw;

    &__title {
        font-size: 6vw;
    }

    &__img {
        text-align: center;
        width: 30vw;
    }

    &__explanation {
        margin: 5vw;
        text-align: left;
        font-size: 4vw;
    }
  }
}

.container-right {
    width: 100%;
    padding-bottom: 3vh;
    
    &__element {
        text-align: center;
        box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.5);
        background-color: white;
        width: 90vw;
        margin: 5vw;

    &__title {
        font-size: 6vw;
    }

    &__img {
        text-align: center;
        width: 30vw;
    }

    &__explanation {
        margin: 5vw;
        text-align: left;
        font-size: 4vw;
    }
  }
}

</style>

