import Vue from 'vue';
import Router from 'vue-router';
import Home from "./views/Home.vue";

// Vue.useはルーターのプラグイン(グローバルの機能)を使う
Vue.use(Router)

export default new Router({
    mode : "history",
    routes: [
        {path : "/", component: Home},
    ]
});



