<template>
  <div class="career">
    <div class="career__title">
      <p class="career__title__font">Who am I?</p>
    </div>

    <div class="career-top">
      <div class="career-top__icon">
        <img :src=img1 class="career-top__icon__picture">
      </div>

      <div class="career-top__introduction">
        <p class="career-top__introduction__say-hello">hello!<p>
        <p class="career-top__introduction__plane-text">I'm Hikaru and I'm from Japan.</p>
        <p class="career-top__introduction__plane-text">Nice to meet you !!</p>
      </div>
    </div>

    <div class="career-bottom">
      <div class="career-bottom__business">
        <h2>What i can do.</h2>
        <ul>
          <li>House cleaning and business cleaning</li>
          <p>I have 8 years of experience house cleaning and business cleaning.</p>
          <li>Transportation industry</li>
          <p>I have an international driver's license and used to drive a lot in Japan.</p>
        </ul>
      </div>

      <div class="career-bottom__future">
        <h2>comment</h2>
        <p>I worked as a business owner in Japan, and came to Australia to make friends overseas and learn about working styles and cultures.
        I'm still learning English, but feel free to contact me!</p>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'PlofileInfomation',
  data: () => ({
    img1: require("@/assets/img/profile_picture.png"),
  }),
}
</script>

<style lang="scss" scoped>

.career {
  overflow: hidden;
  background-color: rgb(208, 246, 255);
  height: auto;

  &__title {
    margin: 2vh;
    text-align: center;
    width: 100vw;

    &__font {
      font-size: 5vw;
    }
  }

  &-top {
    display: flex;
    width: 100%;
    height: auto;
    margin-top: 5vh;

    &__icon {
      left: 0px;
      width: 49%;
      text-align: center;

      &__picture {
        width: auto;
        max-height: 40vh;
        border-radius: 200px;
        background-color: aliceblue;
      }
    }
  
    &__introduction {
      width: 50%;
      right: 0px;

      &__say-hello {
        font-size: 3vw;
      }

      &__plane-text {
        font-size: 2.5vw;
      }
    }
  }

  &-bottom {
    display: flex;
    width: 100%;
    height: auto;
    margin-top: 5vh;

    &__business {
      padding-left: 10vw;
      width: 49vw;
    }

    &__future {
      width: 50vw;
      padding-right: 10vw;
    }


    
  }
}




</style>

